@import "./variables.scss";

.contact {
  background-color: white;
  width: 100%;
  padding: 2.5rem 1.25rem;

  @media screen and (min-width: 576px) {
    padding: 5rem 2.5rem;
  }

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 1.5rem;

      @media screen and (min-width: 576px) {
        font-size: 1.7rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.9rem;
      }
    }
    h3 {
      font-size: 1.1rem;

      @media screen and (min-width: 576px) {
        font-size: 1.3rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }

  .connect-with-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .link {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0rem;
      }

      img {
        margin-right: 1rem;
      }

      a {
        color: black;
        font-size: 1.1rem;
        font-weight: 700;
        text-decoration: none;

        @media screen and (min-width: 576px) {
          font-size: 1.3rem;
        }
      }
    }
  }
}
