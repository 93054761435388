@import "./variables.scss";

.slider {
  position: relative;
  width: 100%;
  background-color: white;
  padding-bottom: 2.5rem;

  @media screen and (min-width: 576px) {
    padding-bottom: 5rem;
  }

  .glide {
    .glide__slide {
      display: grid;
      place-items: center;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
