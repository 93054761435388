@import "./variables.scss";

.alpin-intro {
  background-color: white;
  width: 100%;
  padding: 2.5rem 1.25rem;

  @media screen and (min-width: 576px) {
    padding: 5rem 2.5rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 5rem 0rem;
    width: 80%;
    margin: 0 auto;
  }

  @media screen and (min-width: 1600px) {
    padding: 5rem 0rem;
    width: 1536px;
    margin: 0 auto;
  }

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 1.5rem;

      @media screen and (min-width: 576px) {
        font-size: 1.7rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.9rem;
      }
    }
    h3 {
      font-size: 1.1rem;
      animation-delay: 0.5s !important;
      margin-bottom: 0 !important;

      @media screen and (min-width: 576px) {
        font-size: 1.3rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }

  .grid {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 0fr);
    grid-column-gap: 0px;
    grid-row-gap: 1.25rem;

    .grid-item-content {
      text-align: justify;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .grid-item-image {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 75%;
      }
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
      .grid-item-content {
        text-align: justify;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .grid-item-image {
        text-align: center;
        img {
          width: 90%;
        }
      }
    }

    @media screen and (min-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
      .grid-item-content {
        text-align: justify;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .grid-item-image {
        text-align: center;
        img {
          width: 75%;
        }
      }
    }
  }
}
