@import "./variables.scss";

.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("./images/banner.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $main-text-color;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.14);
    inset: 0;
  }

  @media screen and (min-width: 576px) {
    padding: 2.5rem;
  }

  .heading {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;

    h2 {
      font-size: 2rem;
      // @extend .trackingInExpandFwdTop;

      @media screen and (min-width: 576px) {
        font-size: 2.2rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 2.4rem;
        margin-bottom: 2rem;
      }
    }

    h3 {
      font-size: 1.2rem;
      animation-delay: 1s !important;
      // @extend .trackingInExpand;

      @media screen and (min-width: 576px) {
        font-size: 1.4rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.6rem;
        margin-bottom: 2.5rem;
      }
    }

    a.btn-services {
      display: inline-block;
      padding: 10px 22px;
      border-radius: 36px;
      background-color: $red;
      color: white;
      text-decoration: none;
      text-align: center;
      animation-delay: 1.5s !important;
      transition: all 0.5s ease-in-out;
      // @extend .trackingInExpandFwdBottom;
      &:hover {
        background-color: $light-red;
      }

      @media screen and (min-width: 576px) {
        font-size: 1.3rem;
      }
    }
  }
}
