#alpin105 {
  opacity: 0;
}


#navbar {
  transform: translate(0px, -80px);
  opacity: 0;
}

#bannerH2,
#bannerH3,
#btn-services {
  opacity: 0;
  transform: translate(40px, 0px);
}
