@import "./variables.scss";

.about {
  background-color: white;
  width: 100%;
  padding: 2.5rem 1.25rem 0rem 1.25rem;

  @media screen and (min-width: 576px) {
    padding: 5rem 2.5rem 0rem 2.5rem;
  }

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 1.5rem;

      @media screen and (min-width: 576px) {
        font-size: 1.7rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.9rem;
      }
    }
    h3 {
      font-size: 1.1rem;
      margin-bottom: 2rem;

      @media screen and (min-width: 576px) {
        font-size: 1.3rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }
}
