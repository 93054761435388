@import url("https://fonts.googleapis.com/css2?family=Martel:wght@200;300;400;600;700;800;900&display=swap");
@import "./styles/variables.scss";
@import "./styles/banner.scss";
@import "./styles/navigation.scss";
@import "./styles/services.scss";
@import "./styles/contact.scss";
@import "./styles/slider.scss";
@import "./styles/about.scss";
@import "./styles/alpin_intro.scss";
@import "./styles/animations.scss";

@import "viewerjs/dist/viewer.css";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Martel", serif;
  position: relative;
  overflow-x: hidden;
}

h2 {
  margin: 0;
  color: $main-text-color;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 900;
}

h3 {
  margin: 0;
  color: $secondary-text-color;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: $border;
  padding: 1rem 1.25rem;

  @media screen and (min-width: 576px) {
    padding: 1rem 2.5rem;
  }

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin: 0;
    line-height: 1.1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-22 23:49:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * tracking-in-expand-fwd-top
 * tracking-in-expand
 * tracking-in-expand-fwd-bottom
 * ----------------------------------------
 */
/* --- Animations --- */
@-webkit-keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.trackingInExpandFwdTop {
  -webkit-animation: tracking-in-expand-fwd-top 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.trackingInExpand {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
    transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
    transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.trackingInExpandFwdBottom {
  -webkit-animation: tracking-in-expand-fwd-bottom 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand-fwd-bottom 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.splide__slide img {
  height: 350px!important;
  width: auto!important;
}
