@import "./variables.scss";

header {
  position: relative;
  nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2.5rem;
    transition: all 0.4s ease;

    .mobile {
      background: $main-text-color;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: none;
      align-items: flex-end;
      z-index: 4;

      @media screen and (min-width: 1024px) {
        display: none;
      }

      .navigation {
        padding-top: 70px;
        width: 100%;
        height: calc(100% - 70px);
        background-color: $main-text-color;
        padding: 70px 2.5rem 70px 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
          text-decoration: none;
          color: white;
          text-transform: uppercase;
          letter-spacing: 1.1;
          font-weight: 700;
          font-size: 1.4rem;
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }

    .brand {
      z-index: 5;
      a {
        position: relative;
        text-decoration: none;
        margin: 0;
        font-weight: 700;
        font-size: 1.4rem;
        transition: 0.25s ease-in-out;
        color: $main-text-color;

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          width: 100%;
          height: 2px;
          background-color: $red;
          transform-origin: center;
          transform: translateX(-50%) scaleX(0);
          transition: transform 0.4s ease;
        }

        &::after {
          bottom: 0;
        }

        &:hover::after {
          transform: translateX(-50%) scaleX(1);
        }

        @media screen and (min-width: 768px) {
          font-size: 1.6rem;
        }
      }
    }

    .desktop-navigation {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      @media screen and (max-width: 1023px) {
        display: none;
      }

      a {
        position: relative;
        text-decoration: none;
        color: $main-text-color;
        margin: 0;
        margin-right: 2rem;
        font-weight: 500;
        font-size: 1.4rem;
        transition: 0.25s ease-in-out;

        &:last-child {
          margin-right: 0px;
        }

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: $red;
          transform-origin: center;
          transform: translate(-50%, 0) scaleX(0);
          transition: transform 0.3s ease-in-out;
        }

        &:hover::before {
          transform: translate(-50%, 0) scaleX(1);
        }

        @media screen and (min-width: 768px) {
          font-size: 1.5rem;
        }
      }
    }

    #hamburger {
      z-index: 5;
      width: 30px;
      height: 30px;
      position: relative;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;

      @media screen and (min-width: 1024px) {
        display: none;
      }

      &.open {
        span {
          background: white;
        }
      }

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: $main-text-color;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 3px;
        }
        &:nth-child(2) {
          top: 12px;
        }
        &:nth-child(3) {
          top: 12px;
        }
        &:nth-child(4) {
          top: 21px;
        }
      }
    }
    .open {
      span {
        &:nth-child(1) {
          top: 18px !important;
          width: 0% !important;
          left: 50% !important;
        }
        &:nth-child(2) {
          transform: rotate(45deg) !important;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) !important;
        }
        &:nth-child(4) {
          top: 18px !important;
          width: 0% !important;
          left: 50% !important;
        }
      }
    }
  }
}

.scrolled {
  padding: 0.5rem 2.5rem;
  background-color: $main-text-color;

  .brand {
    a {
      color: #fff;
    }
  }

  .desktop-navigation {
    a {
      color: #fff;
    }
  }

  #hamburger {
    span {
      background: #fff;
    }
  }
}

.colorWhite {
  color: white !important;
}
